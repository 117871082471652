/* eslint-disable jsx-a11y/alt-text */
"use client";

import { useEffect, useRef, useState } from "react";
import Button from "../../Button";
import Input from "../../Input";
import SearchableSelect from "../../SearchableSelect";
import { api } from "../../../../services/api";
import { Box, InfIle, ContainerInputs, WidthBtn } from "./styles";
import { FaPaperclip } from "react-icons/fa6";
import Default from "../../../../assets/default.png";
import Spacer from "../../Spacer";
import Typography from "../../Typography";

const Image_Default = Default;

const Form = () => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedGym, setSelectedGym] = useState<any>(null);
  const [gyms, setGyms] = useState([]);
  
  // Referências para os campos do formulário
  const nomeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const [gender, setGender] = useState(""); // Controle do gênero

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get("/students/me");
        if (response.status === 200) {
          const data = response.data;
          nomeRef.current!.value = data.nome || "";
          emailRef.current!.value = data.email || "";
          phoneRef.current!.value = data.phone || "";
          birthDateRef.current!.value = data.birthDate || "";
          setGender(data.gender || "");
          setImagePreview(data.profilePictureUrl || Image_Default);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setErrorMessage("Erro ao carregar dados.");
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchGyms = async () => {
      const response = await api.get("/gyms");
      setGyms(response.data);
    };
    fetchGyms();
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.patch(
        "/students/me",
        {
          name: nomeRef.current?.value || "", // Adicione o nome aqui
          birth_date: birthDateRef.current?.value || "", // Adicione a data de nascimento aqui
          mail: emailRef.current?.value || "", // Adicione o email aqui
          telefone: phoneRef.current?.value || "", // Adicione o telefone aqui
          celular:phoneRef.current?.value || "", // Adicione o celular aqui
          sex: gender, // Adicione o sexo aqui
          address: {
            street: "", // Adicione a rua aqui
            neighborhood: "", // Adicione o bairro aqui
            zip_code: "", // Adicione o CEP aqui
          },
        },
      );
      console.log("Response data:", response.data);
      if (response.status === 200) {
        setSuccessMessage("Dados salvos com sucesso!");
        setImagePreview(response.data.Foto || Image_Default);
      } else {
        setErrorMessage("Erro ao salvar os dados.");
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      setErrorMessage("Erro ao salvar os dados.");
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box width="x" height="x" display="flex">
        <ContainerInputs>
          <div>
            <Typography type="Caption">Nome completo*</Typography>
            <Input
              type="text"
              placeholder="Seu nome"
              ref={nomeRef}
            />
            <Spacer height="16px" />
            <Typography type="Caption">Email*</Typography>
            <Input
              type="email"
              placeholder="Seu email"
              ref={emailRef}
            />
          </div>
          <div>
            <Typography type="Caption">Data de nascimento*</Typography>
            <Input
              type="date"
              placeholder="Data de nascimento"
              ref={birthDateRef}
            />
            <Spacer height="16px" />
            <Typography type="Caption">Telefone</Typography>
            <Input
              type="tel"
              placeholder="Telefone"
              ref={phoneRef}
            />
          </div>
          <div>
            <Typography type="Caption">Sexo</Typography>
            <SearchableSelect
              placeholder="Selecionar"
              data={[
                { id: 1, label: "Masculino" },
                { id: 2, label: "Feminino" },
                { id: 3, label: "Outro" },
              ]}
              onSelectionChange={(selectedOption) => {
                const genderValue = selectedOption.id === 1 
                  ? "M" 
                  : selectedOption.id === 2 
                  ? "F" 
                  : "O";
                setGender(genderValue);
                console.log("Selected gender:", genderValue);
              }}
            />
          </div>
        </ContainerInputs>
      </Box>

      <Spacer height="24px" />

      <Box width="y" height="y" display="flex">
        <InfIle>
          <div className="upload-container">
            <label>Foto de perfil</label>
            <label htmlFor="file" className="upload-button">
              <div className="icon-container">
                <FaPaperclip size={24} />
              </div>
            </label>
            <input
              type="file"
              id="file"
              name="profilePicture"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          <div
            className="image-preview"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            <img src={imagePreview || Image_Default} alt="Pré-visualização" />
          </div>
        </InfIle>
      </Box>

      <Spacer height="24px" />

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}

      <WidthBtn>
        <Button $variante="Submit" type="button" onClick={handleSubmit}>Salvar</Button>
      </WidthBtn>
    </form>
  );
};

export { Form };
