import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../hooks";
import { Card, Progresso } from "../Dashboard/Header/styles";
import Skeleton from "../../_ui/Skeleton";
import Typography from "../Typography";
import { ptBR } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";
import { toUpperCaseFirstLetter } from "../../../utils/utils";

const FinancialStatus: React.FC = () => {
  const [paid, setPaid] = useState<boolean>(false);
  const [month, setMonth] = useState<string>("");

  const { isLoading, tabelaPG } = usePageContext();
  const timeZone = "America/Sao_Paulo";

  useEffect(() => {
    if (tabelaPG && tabelaPG.length > 0) {
      const { date, status } = tabelaPG[0]; 

      console.log(date);
      console.log(status);
      console.log(paid);
      
      if (date) {
        const dueDate = new Date(date);
        setMonth(formatInTimeZone(dueDate, timeZone, "MMMM", {
          locale: ptBR,
        }));

        
        setPaid(status?.description?.toUpperCase() === "PAID");
      }
    }
  }, [tabelaPG, paid]);

  return (
    <Card>
      <Typography type="Caption" color="#595a5c" wight="400">INFORMAÇÃO FINANCEIRA</Typography>
      <Typography type="Subtitle" wight="700">
        {toUpperCaseFirstLetter(month)}
      </Typography>
      <Progresso>
        {isLoading ? (
          <Skeleton width="120px" height="30px" />
        ) : (
          <div className={`${paid ? "paga" : "naopaga"}`}>
            {paid ? "Pago" : "Pendente"}
          </div>
        )}

        <div>
          <Typography type="Caption" wight="400">Situação financeira</Typography>
        </div>
      </Progresso>
    </Card>
  );
};

export default FinancialStatus;
