import React, { useState } from "react";
import {
  Container,
  ContainerItem,
  IconWrapper,
  MenuContainer,
  RedirectItem,
  Hamburger,
  Overlay,
} from "./styles";
import { RiApps2Fill } from "react-icons/ri";
import { FaFileArrowDown } from "react-icons/fa6";
import { IoFolder } from "react-icons/io5";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";

import { FaBars, FaTimes } from "react-icons/fa";
import sagaz_color from "../../../assets/sagaz_color.png";
import Spacer from "../Spacer";
import Typography from "../Typography";
import { useNavigate } from "react-router-dom";

interface IMenuItem {
  $isActive?: boolean;
  Icon: any;
  title: string;
  text: string;
  onClick: () => void;
}

const MenuItem: React.FC<IMenuItem> = ({
  $isActive = false,
  Icon,
  title,
  text,
  onClick,
}) => {
  return (
    <ContainerItem $isActive={$isActive} onClick={onClick}>
      <Icon />
      <Spacer width="10px" />
      <RedirectItem $isActive={$isActive}>
        <Typography type="Body" color={$isActive ? "#155681" : "#6A6C71"}>
          {text}
        </Typography>
      </RedirectItem>
    </ContainerItem>
  );
};

const Menu: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>("Dashboard");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (item: string, path: string) => {
    setActiveItem(item);
    setIsMenuOpen(false);
    navigate(path);
  };

  return (
    <Container>
      <Hamburger>
        <div
          style={{
            width: "200px",
          }}
          
        >
          <img alt="Logo" src={sagaz_color} width={"50%"} onClick={() => handleMenuItemClick("Dashboard", "/home")}
          />
        </div>

        <IconWrapper
          onClick={toggleMenu}
          className={isMenuOpen ? "open" : "closed"} 
        >
          {!isMenuOpen ? <FaBars size={30} /> : <FaTimes size={30} />}
        </IconWrapper>
      </Hamburger>
      {isMenuOpen && <Overlay $isOpen={isMenuOpen} onClick={toggleMenu} />}{" "}
      <MenuContainer $isOpen={isMenuOpen}>
      
        <div
          style={{
            width: "100%",
            height: "8%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px 0px",
            cursor: "pointer",
          }}

          onClick={() => handleMenuItemClick("Dashboard", "/home")}
        >
          <img alt="Logo" src={sagaz_color} width={"50%"}  />
        </div>
          
        <MenuItem
          $isActive={activeItem === "Dashboard"}
          Icon={() => (
            <RiApps2Fill
              color={activeItem === "Dashboard" ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Dashboard"
          text="Dashboard"
          onClick={() => handleMenuItemClick("Dashboard", "/home")}
        />

        <MenuItem
          $isActive={activeItem === "Historico"}
          Icon={() => (
            <FaFileArrowDown
              color={activeItem === "Historico" ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Historico"
          text="Histórico"
          onClick={() => handleMenuItemClick("Historico", "/historico")}
        />

        <MenuItem
          $isActive={activeItem === "Matricula"}
          Icon={() => (
            <IoFolder
              color={activeItem === "Matricula" ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Matricula"
          text="Matrícula"
          onClick={() => handleMenuItemClick("Matricula", "/matricula")}
        />

        <MenuItem
          $isActive={activeItem === "Financeiro"}
          Icon={() => (
            <FaMoneyCheckDollar
              color={activeItem === "Financeiro" ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Financeiro"
          text="Financeiro"
          onClick={() => handleMenuItemClick("Financeiro", "/financeiro")}
        />

        <MenuItem
          $isActive={activeItem === "Perfil"}
          Icon={() => (
            <FaUser
              color={activeItem === "Perfil" ? "#155681" : "#6A6C71"}
              size="30"
            />
          )}
          title="Perfil"
          text="Perfil"
          onClick={() => handleMenuItemClick("Perfil", "/perfil")}
        />
      </MenuContainer>
    </Container>
  );
};

export default Menu;
