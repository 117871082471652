/* eslint-disable jsx-a11y/alt-text */
"use client";

import Button from "../../components/_ui/Button";
import Input from "../../components/_ui/Input";
import SearchableSelect from "../../components/_ui/SearchableSelect";
import { api } from "../../services/api";
import { useEffect, useRef, useState } from "react";
import { Card, Container } from "./styles";
import sagaz_color from "../../assets/sagaz_color.png";
import Spacer from "../../components/_ui/Spacer";
import { useNavigate } from "react-router-dom";
import { usePageContext } from "../../hooks";
// import { useModal } from "../../hooks/useModal";

export default function Page() {
  const navigate = useNavigate();
  const { setIsLogged, setUser } = usePageContext();
  const enrollmentRef = useRef<HTMLInputElement>(null);
  const birthdayRef = useRef<HTMLInputElement>(null);
  const [selectedGym, setSelectedGym] = useState<any>(null);
  const [gyms, setGyms] = useState([]);

  // const { showModal } = useModal();

  // const handleClick = () => {
  //   console.log("click");
  //   showModal(<div>Conteúdo do Modal com styled-components e TypeScript!</div>);
  //   showModal(<div>Primeiro Alerta</div>);
  //   showModal(<div>Segundo Alerta</div>);
  // };


  useEffect(() => {
    const init = async () => {
      const response = await api.get("/gyms");
      setGyms(response.data);
    };

    init();
  }, []);

  async function handleSubmit() {
    const response = await api.post(
      "/sessions",
      {
        enrollment: enrollmentRef.current?.value || "6123",
        birthday: birthdayRef.current?.value || "2001-03-20",
      },
      {
        headers: {
          "x-academy": selectedGym?.label || "A3 ACADEMIA",
        },
      }
    );

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data));
    setUser({
      isLoading: false,
      data: response.data,
    });
    setIsLogged(true);
    navigate("/home");
  }

  return (
    <Container>
      <Card>
        <img src={sagaz_color} width={"50%"} />
        <Spacer height={"1em"} />
        <Input
          type="text"
          placeholder="Número matricula"
          name="enrollment"
          ref={enrollmentRef}
        />
        <Spacer height={"1em"} />
        <Input
          type="date"
          placeholder="Data de nascimento"
          name="birthday"
          ref={birthdayRef}
        />
        <Spacer height={"1em"} />
        <SearchableSelect
          placeholder="Selecione a academia"
          data={gyms}
          onSelectionChange={(d) => {
            console.log(d);
            setSelectedGym(d);
          }}
        />
        <Spacer height={"1em"} />
        <Button
          $variante="primary"
          type="button"
          onClick={() => handleSubmit()}
        >
          Entrar
        </Button>
        {/* <Spacer height={"1em"} />
        <Button
          $variante="primary"
          type="button"
          onClick={() => handleClick()}
        >
          Block
        </Button> */}
      </Card>
    </Container>
  );
}
