import React, { useEffect, useState } from "react";
import { FaCopy, FaHistory } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa6";
import Formas from "../../../FormasPagament";
import Spacer from "../../../Spacer";
import Typography from "../../../Typography";
import Home from "../../../../../pages/Home";
import { api } from "../../../../../services/api";
import {
  ContainerPix,
  ConteudoPix,
} from "./styles"; // Certifique-se de que os caminhos estão corretos
import { ContainerResponsive, NavegaFormas } from "../../../../../pages/Financeiro/styles";
import { toggles } from "../../../../../enums/toggles";
import CheckToggle from "../../../CheckToggle";

const Pix: React.FC = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const [pixPayload, setPixPayload] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPixPayload = async () => {
      setLoading(true);
      try {
        const response = await api.post("/students/me/monthly-payments/pix");
        const payload = response.data.qr_code;
        const qrCodeBase64 = response.data.qr_code_base64;

        setPixPayload(payload);

        if (qrCodeBase64) {
          setQrCodeUrl(`data:image/png;base64,${qrCodeBase64}`);
        } else {
          setError("QR Code em base64 não disponível.");
        }
      } catch (err: any) {
        console.error("Erro ao gerar o QR Code:", err);
        setError("Erro ao gerar o QR Code. Por favor, tente novamente mais tarde.");
      } finally {
        setLoading(false);
      }
    };

    fetchPixPayload();
  }, []);

  const copyToClipboard = () => {
    if (pixPayload) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(pixPayload)
          .then(() => {
            setCopySuccess("Código PIX copiado com sucesso!");
            setTimeout(() => setCopySuccess(null), 3000);
          })
          .catch(() => {
            setCopySuccess("Falha ao copiar o código PIX.");
            setTimeout(() => setCopySuccess(null), 3000);
          });
      } else {
        // Fallback para navegadores mais antigos
        const textArea = document.createElement("textarea");
        textArea.value = pixPayload;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          setCopySuccess("Código PIX copiado com sucesso!");
        } catch (err) {
          setCopySuccess("Falha ao copiar. Selecione e copie manualmente.");
        }
        document.body.removeChild(textArea);
        setTimeout(() => setCopySuccess(null), 3000);
      }
    }
  };

  return (
    <ContainerPix>
      <Home />
      <Typography type="Subtitle">Forma de pagamento</Typography>
      <Spacer height="24px" />

      <NavegaFormas>

        <CheckToggle toggleName={toggles.PAYMENT_CREDIT_CARD}>
          <Formas
            title="Cartão de Crédito"
            Photo={() => <FaCreditCard size={24} style={{ color: "gray" }} />}
            link="cartao"
          />
        </CheckToggle>
        <Formas
          title="Histórico"
          Photo={() => <FaHistory size={24} style={{ color: "gray" }} />}
          link="financeiro"
        />
      </NavegaFormas>
      <Spacer height="24px" />

      <ContainerResponsive>
        <ConteudoPix>
          <Typography type="Caption">
            Digitalize o código PIX QR com seu telefone celular
          </Typography>

          {loading ? (
            <Typography type="Overline">Gerando QR Code...</Typography>
          ) : error ? (
            <Typography type="Overline" color="red">
              {error}
            </Typography>
          ) : qrCodeUrl ? (
            <img
              src={qrCodeUrl}
              alt="QR Code Pix"
              style={{
                width: "250px",
                margin: "20px 0",
              }}
            />
          ) : null}

          {pixPayload && !loading && !error && (
            <>
              <button onClick={copyToClipboard} disabled={loading}>
                <FaCopy size={24} /> Copiar Codigo
              </button>

              {copySuccess && (
                <span
                  style={{
                    marginTop: "10px",
                    color: copySuccess.includes("sucesso") ? "green" : "red",
                  }}
                >
                  {copySuccess}
                </span>
              )}
            </>
          )}
        </ConteudoPix>
      </ContainerResponsive>
    </ContainerPix>
  );
};

export default Pix;
